import {
  FormElement
} from "./chunk.UTVDNTSP.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  applyStyles
} from "./chunk.C3CSSY4S.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n,
  r as r3
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-text-field/jb-text-field.scss?inline
var jb_text_field_default = i`jb-text-field {
  /* stylelint-disable */
  /* COLOR SETTINGS */
  /* shadows */
  /* special measurements */
  /* stylelint-disable */
  /* stylelint-enable */
  /* special measurements */
  /* stylelint-enable */
  display: block;
  flex: 1;
  width: 100%;
}
jb-text-field *,
jb-text-field *::before,
jb-text-field *::after {
  box-sizing: border-box;
}
jb-text-field div,
jb-text-field object,
jb-text-field iframe,
jb-text-field h1,
jb-text-field h2,
jb-text-field h3,
jb-text-field h4,
jb-text-field h5,
jb-text-field h6,
jb-text-field p,
jb-text-field blockquote,
jb-text-field ol,
jb-text-field ul,
jb-text-field li,
jb-text-field form,
jb-text-field legend,
jb-text-field label,
jb-text-field table,
jb-text-field header,
jb-text-field footer,
jb-text-field nav,
jb-text-field section,
jb-text-field figure {
  margin: 0;
  padding: 0;
}
jb-text-field a {
  color: inherit;
}
jb-text-field :host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}
@media screen and (width >= 1281px) {
  jb-text-field :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
jb-text-field .counter, jb-text-field p, jb-text-field > div:not(.combine) label, jb-text-field > div:not(.combine) input::placeholder, jb-text-field > div:not(.combine) input {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}
jb-text-field > div:not(.combine) input::placeholder, jb-text-field > div:not(.combine) input {
  font-size: var(--theme-typo-p-small-desktop-fontsize, 1rem);
  line-height: var(--theme-typo-p-small-desktop-lineheight, 1.625rem);
}
jb-text-field .counter, jb-text-field p, jb-text-field > div:not(.combine) label {
  font-size: var(--theme-typo-p-mini-desktop-fontsize, 0.813rem);
  line-height: var(--theme-typo-p-mini-desktop-lineheight, 1.25rem);
}
jb-text-field :host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}
@media screen and (width >= 1281px) {
  jb-text-field :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
jb-text-field :host {
  color: var(--host-color, #141e55);
}
jb-text-field > div:not(.combine) {
  flex-wrap: wrap;
  padding-top: 22px;
  position: relative;
}
@media (max-width: 959px) {
  jb-text-field > div:not(.combine) {
    display: block;
  }
}
jb-text-field > div:not(.combine) label {
  color: rgba(var(--rgb-primary, 20, 30, 85, var(--theme-opacity-80, 0.8)));
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 2px;
  transition: opacity 0.3s ease-in 0s;
}
jb-text-field > div:not(.combine) input {
  -webkit-appearance: none;
  background: rgba(var(--background-color, 255, 255, 255));
  border: none;
  border-bottom: 1px solid rgba(var(--rgb-primary, 20, 30, 85), var(--border-opacity, var(--theme-opacity-30, 0.3)));
  border-radius: 0;
  color: rgba(var(--rgb-primary, 20, 30, 85));
  padding-block: var(--spacing-base);
  width: 100%;
}
@media all and (-ms-high-contrast: none) {
  jb-text-field > div:not(.combine) input {
    padding-bottom: 14px;
  }
}
jb-text-field > div:not(.combine) input::placeholder {
  color: rgba(var(--rgb-primary, 20, 30, 85), 0.8);
  opacity: 1;
  transition: opacity 0.3s linear 0s;
}
jb-text-field > div:not(.combine) input:focus {
  border-bottom: 1px solid rgba(var(--rgb-primary, 20, 30, 85), var(--border-opacity, 1));
  outline: none;
}
jb-text-field > div:not(.combine) input:focus::placeholder {
  opacity: 0;
  transition: opacity 0.3s linear 0s;
}
jb-text-field > div:not(.combine) input:focus + label {
  opacity: 1;
  transition: opacity 0.3s ease-in 0s;
}
jb-text-field > div:not(.combine) input:not(:placeholder-shown) + label {
  opacity: 1;
  transition: opacity 0.3s ease-in 0s;
}
jb-text-field > div:not(.combine) .error {
  border-color: #971b2f;
}
jb-text-field > div:not(.combine) .error::placeholder,
jb-text-field > div:not(.combine) .error + label {
  color: #971b2f;
}
jb-text-field > div:not(.combine).hidden {
  display: none;
}
jb-text-field p {
  color: #971b2f;
  display: block;
  margin-top: var(--spacing-base);
}
jb-text-field .white input {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
jb-text-field .white input::placeholder {
  color: #fff;
}
jb-text-field .white input + label {
  color: #fff;
}
jb-text-field .white input:focus {
  border-bottom: 1px solid #fff;
  outline: none;
}
jb-text-field .white .error {
  border-color: #ff5570 !important;
}
jb-text-field .white .error::placeholder,
jb-text-field .white .error + label {
  color: #ff5570 !important;
}
jb-text-field .white p {
  color: #ff5570;
}
jb-text-field .counter {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-block-start: var(--spacing-base);
  width: 100%;
}
jb-text-field .counter > span:first-of-type {
  opacity: 0.7;
}`;

// src/components/jb-text-field/jb-text-field.ts
var JbTextField = class extends Initiable(FormElement(r2)) {
  constructor() {
    super(...arguments);
    this._currentLength = 0;
    this._showEmailError = false;
  }
  connectedCallback() {
    super.connectedCallback();
    applyStyles(this, jb_text_field_default);
  }
  createRenderRoot() {
    return this;
  }
  validate() {
    let valid = super.validate();
    if (valid && this.type === "email") {
      this._showEmailError = this.value === "" && this.requiredText === "" ? false : !this.validateEmail();
      valid = !this._showEmailError;
    }
    return valid;
  }
  validateEmail() {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return re.test(String(this.value).toLowerCase());
  }
  _handleInputChange(e) {
    this.value = e.target.value;
    this._currentLength = this.value.length;
    this.validate();
  }
  render() {
    return ke`
      <div class=${Rt({ white: !!this.isWhite, hidden: this.type === "hidden" })}>
        <input
          class=${Rt({
      optional: this.requiredText === "" || !this.requiredText,
      error: this.showError || this._showEmailError || !!this.errorText
    })}
          ?disabled=${this.disabled}
          .name=${this.name}
          ?required=${!!this.requiredText}
          .value=${typeof this.value === "string" ? this.value : ""}
          .type=${this.type}
          .id=${this.name}
          placeholder="${this.placeholder}${this.requiredText ? "*" : ""}"
          .autocomplete=${this.autocomplete ?? "on"}
          @change=${this._handleInputChange}
          .maxLength=${this.maxLength ?? D}
          @input=${(evt) => {
      if (!this.maxLength) return;
      this._currentLength = evt.currentTarget.value.length;
    }}
        />
        ${this.maxLength ? ke`<div class="counter">
              <span>${this.maxLengthText ?? D}</span><span>${this._currentLength} / ${this.maxLength}</span>
            </div>` : D}
        <label for=${this.name}>${this.label}${this.requiredText ? "*" : ""}</label>
        ${this.showError || this.errorText || this._showEmailError ? ke`<p>
              ${this.showError ? ke`<span>${this.requiredText}</span>` : D}
              ${this.errorText ? ke`<span>${this.errorText}</span>` : D}
              ${!this.showError && this._showEmailError ? ke`<span>${this.emailErrorText}</span>` : D}
            </p>` : D}
      </div>
    `;
  }
};
JbTextField.styles = r(jb_text_field_default);
__decorateClass([
  n()
], JbTextField.prototype, "autocomplete", 2);
__decorateClass([
  n()
], JbTextField.prototype, "emailErrorText", 2);
__decorateClass([
  n({ type: Boolean })
], JbTextField.prototype, "isWhite", 2);
__decorateClass([
  n({ type: Number })
], JbTextField.prototype, "maxLength", 2);
__decorateClass([
  n()
], JbTextField.prototype, "maxLengthText", 2);
__decorateClass([
  n()
], JbTextField.prototype, "placeholder", 2);
__decorateClass([
  n()
], JbTextField.prototype, "type", 2);
__decorateClass([
  r3()
], JbTextField.prototype, "_currentLength", 2);
__decorateClass([
  r3()
], JbTextField.prototype, "_showEmailError", 2);
JbTextField = __decorateClass([
  customElement("jb-text-field")
], JbTextField);

export {
  JbTextField
};
