import {
  ae
} from "./chunk.RIMVGMXQ.js";
import {
  Spaceable
} from "./chunk.LDSR6LCO.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  jbLinkClickedEventToJbTrackingEvent
} from "./chunk.42LHJR2F.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  awaitForEach,
  isPartiallyInViewPort
} from "./chunk.C3CSSY4S.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n,
  r2 as r3
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-facts/jb-facts.scss?inline
var jb_facts_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
.title {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

h2 {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

h2 {
  font-size: var(--theme-typo-h2-subtitle-mobile-fontsize, 1.625rem);
  line-height: var(--theme-typo-h2-subtitle-mobile-lineheight, 2.188rem);
}
@media (min-width: 1281px) {
  h2 {
    font-size: var(--theme-typo-h2-subtitle-desktop-fontsize, 1.938rem);
    line-height: var(--theme-typo-h2-subtitle-desktop-lineheight, 2.625rem);
  }
}

.title {
  font-size: var(--theme-typo-p-highlight-mobile-fontsize, 1.063rem);
  line-height: var(--theme-typo-p-highlight-mobile-lineheight, 1.875rem);
}
@media (min-width: 1281px) {
  .title {
    font-size: var(--theme-typo-p-highlight-desktop-fontsize, 1.188rem);
    line-height: var(--theme-typo-p-highlight-desktop-lineheight, 2.125rem);
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
  --text-opacity: 1;
  --_transition-step-duration: 0.66s;
  --_timing-function: cubic-bezier(0.83, 1.49, 0.64, 1);
}

h2 {
  margin-block-end: var(--spacing-lm);
}

.container {
  display: block;
  gap: 40px;
}

@media (min-width: 960px) {
  .cols-2 .container {
    column-count: 2;
  }
}

@media (min-width: 1281px) {
  .cols-3 header {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(10, 1fr);
  }
  .cols-3 header > * {
    grid-column: 2/span 8;
  }
}
@media (min-width: 960px) {
  .cols-3 .container {
    column-count: 3;
  }
}

.fact {
  break-inside: avoid;
  display: flex;
  gap: var(--spacing-sm);
  margin-block-end: var(--spacing-lm);
}
@media (min-width: 1281px) {
  .fact {
    margin-block-end: var(--spacing-m);
  }
}
.fact::before {
  background-color: #141e55;
  block-size: auto;
  content: "";
  display: block;
  inline-size: 1px;
  max-block-size: 0;
  transition: max-block-size calc(var(--_transition-step-duration) * 2) var(--_timing-function), opacity var(--_transition-step-duration) ease-in;
}
.fact.show::before {
  block-size: 56px;
  max-block-size: 56px;
  opacity: 0.2;
}
.fact.show .content {
  opacity: 1;
  transform: translateY(0);
}

.content {
  opacity: 0;
  transform: translateY(-50px);
  transition: transform calc(var(--_transition-step-duration) * 2) var(--_timing-function), opacity var(--_transition-step-duration) ease-in var(--_transition-step-duration);
}

.title {
  color: #141e55;
}`;

// src/components/jb-facts/jb-facts.ts
var JbFacts = class extends Spaceable(r2) {
  firstUpdated() {
    this._bindEvents();
    setTimeout(() => {
      this._fadeInIfInViewport(this);
    }, 0);
  }
  _bindEvents() {
    const element = this;
    document.addEventListener("scroll", () => {
      this._fadeInIfInViewport(element);
    });
  }
  _fadeInIfInViewport(element) {
    if (isPartiallyInViewPort(element, 250)) {
      this._startFadeInAnimation().then();
    }
  }
  _getCols() {
    if (this.intranetStyle) {
      return this.columns <= 2 ? this.columns : 2;
    }
    return this.columns;
  }
  _getContentSpacingProperties() {
    const cols = { 1: 8, 2: 8, 3: 10 }[this._getCols()] || 8;
    const align = "center";
    const type = "focus";
    return { align, colsMdUp: cols, type, grid: "legacy" };
  }
  async _startFadeInAnimation() {
    setTimeout(async () => {
      await awaitForEach(this._facts, async (fact) => {
        fact.classList.add("show");
        await new Promise((p) => setTimeout(p, 50));
      });
    }, 0);
  }
  _tracking(e) {
    const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(e, {
      component_name: "facts"
    });
    if (jbTrackingEvent) {
      this.dispatchEvent(jbTrackingEvent);
    }
  }
  render() {
    return ke`
      <section
        class=${Rt({
      ["cols-" + this._getCols()]: true
    })}
      >
        ${this.headline ? ke`<header><h2>${this.headline}</h2></header>` : D}
        <div class="container">
          ${this.items.map(
      (item) => ke`
              <div class="fact">
                <div class="content">
                  <p class="title">${item.title}</p>
                  <jb-richtext
                    @jb-link:clicked=${this._tracking}
                    .size=${"small"}
                    >${ae(item.content)}</jb-richtext
                  >
                </div>
              </div>
            `
    )}
        </div>
      </section>
    `;
  }
};
JbFacts.styles = r(jb_facts_default);
__decorateClass([
  n({ type: Number })
], JbFacts.prototype, "columns", 2);
__decorateClass([
  n()
], JbFacts.prototype, "headline", 2);
__decorateClass([
  n({ type: Boolean })
], JbFacts.prototype, "intranetStyle", 2);
__decorateClass([
  n({ type: Array })
], JbFacts.prototype, "items", 2);
__decorateClass([
  r3(".fact")
], JbFacts.prototype, "_facts", 2);
JbFacts = __decorateClass([
  customElement("jb-facts")
], JbFacts);

export {
  JbFacts
};
