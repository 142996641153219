import {
  FormElement
} from "./chunk.UTVDNTSP.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  e,
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-upload/jb-upload.scss?inline
var jb_upload_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
.label {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

.error-msg, .description,
.file-name {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

.description,
.file-name {
  font-size: var(--theme-typo-p-small-desktop-fontsize, 1rem);
  line-height: var(--theme-typo-p-small-desktop-lineheight, 1.625rem);
}

.label {
  font-size: var(--theme-typo-p-small-highlight-desktop-fontsize, 1rem);
  line-height: var(--theme-typo-p-small-highlight-desktop-lineheight, 1.625rem);
}

.error-msg {
  font-size: var(--theme-typo-p-mini-desktop-fontsize, 0.813rem);
  line-height: var(--theme-typo-p-mini-desktop-lineheight, 1.25rem);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
  width: 100%;
}

.label {
  margin-bottom: var(--spacing-3xs);
}

.description,
.file-name {
  color: rgba(20, 30, 85, var(--theme-opacity-80, 0.8));
  margin-bottom: var(--spacing-xs);
}

.file-name {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-block: var(--spacing-3xs);
  position: relative;
}
.file-name::after {
  background-color: #141e55;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.4s ease-in;
  width: 100%;
}
.file-name::before {
  background-color: rgba(20, 30, 85, var(--theme-opacity-20, 0.2));
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}
.file-name:hover jb-clear-icon {
  color: #141e55;
  transition: color 0.8s ease-in-out;
}
.file-name:hover::after {
  transform: scaleX(1);
  transition: transform 0.8s ease-out;
}
.file-name jb-clear-icon {
  color: rgba(20, 30, 85, var(--theme-opacity-40, 0.4));
  transition: color 0.4s ease-in-out;
}

input[type=file] {
  display: none;
}

.error-msg {
  margin-top: var(--spacing-2xs);
}

.error .label {
  color: #971b2f;
}
.error .file-name {
  border-color: #971b2f;
  color: #971b2f;
}
.error .file-name::before, .error .file-name::after {
  background-color: #971b2f;
}
.error .error-msg {
  color: #971b2f;
  display: block;
}

.disabled {
  opacity: 0.4;
}`;

// src/components/jb-upload/jb-upload.ts
var JbUpload = class extends Initiable(FormElement(r2)) {
  showSelectedFile() {
    this.isUploaded = true;
    this.fileName = this._uploadInputFile.files[0].name;
    [this.value] = Array.from(this._uploadInputFile.files);
    this.dispatchEvent(new CustomEvent("jb-upload:field-change", { bubbles: true, composed: true }));
  }
  _clearInput() {
    this.value = void 0;
    this.fileName = void 0;
  }
  _openFileDialog() {
    this._uploadInputFile.click();
  }
  render() {
    const classes = {
      error: this.showError || !!this.errorText,
      uploaded: !!this.isUploaded,
      disabled: !!this.disabled
    };
    return ke`
      <div class="${Rt(classes)}">
        ${this.label ? ke`<p class="label">${this.label}${this.requiredText ? "*" : D}</p>` : D}
        ${this.description ? ke`<p class="description">${this.description}</p>` : D}

        <input
          ?disabled=${this.disabled}
          ?required=${!!this.requiredText}
          @change=${this.showSelectedFile}
          name=${this.name}
          type="file"
        />

        ${this.fileName ? ke`<p class="file-name">
              <span>${this.fileName}</span><jb-clear-icon @click=${this._clearInput}></jb-clear-icon>
            </p>` : D}

        <jb-button-secondary
          @jb-button:clicked=${this._openFileDialog}
          name="uploadbutton"
          >${this.buttonText}</jb-button-secondary
        >

        ${this.showError || this.errorText ? ke`<p class="error-msg">
              ${this.showError ? ke`<span>${this.requiredText}</span>` : D}
              ${this.errorText ? ke`<span>${this.errorText}</span>` : D}
            </p>` : D}
      </div>
    `;
  }
};
JbUpload.styles = r(jb_upload_default);
__decorateClass([
  n()
], JbUpload.prototype, "buttonText", 2);
__decorateClass([
  n()
], JbUpload.prototype, "description", 2);
__decorateClass([
  n()
], JbUpload.prototype, "fileName", 2);
__decorateClass([
  n({ type: Boolean })
], JbUpload.prototype, "isUploaded", 2);
__decorateClass([
  n()
], JbUpload.prototype, "name", 2);
__decorateClass([
  e('input[type="file"]')
], JbUpload.prototype, "_uploadInputFile", 2);
JbUpload = __decorateClass([
  customElement("jb-upload")
], JbUpload);

export {
  JbUpload
};
