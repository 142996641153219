import {
  FormElement
} from "./chunk.UTVDNTSP.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-radio/jb-radio.scss?inline
var jb_radio_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
p.error, .radios label, .main {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

.radios label, .main {
  font-size: var(--theme-typo-p-small-desktop-fontsize, 1rem);
  line-height: var(--theme-typo-p-small-desktop-lineheight, 1.625rem);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

.error label,
.error label.main {
  color: #971b2f;
}
.error input {
  border-bottom: 1px solid #971b2f;
}
.error input::placeholder {
  color: #971b2f;
}
.error .radios p {
  color: #971b2f;
}
.error .radios p::after {
  border-color: #971b2f;
}
.error .radios p::before {
  border-color: #971b2f;
}

.disabled {
  opacity: 0.4;
}

.main {
  display: inline-block;
  flex-basis: 100%;
  margin-bottom: var(--spacing-xs);
}

.radios {
  display: flex;
  gap: 0 var(--spacing-sm);
}
.radios label {
  color: rgba(20, 30, 85, var(--theme-opacity-80, 0.8));
  display: inline-block;
  position: relative;
  user-select: none;
}
.radios label:hover {
  cursor: pointer;
}
.radios label p {
  align-items: center;
  display: flex;
}
.radios label p::before {
  border: 1px solid #141e55;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 28px;
  margin-right: var(--spacing-2xs);
  width: 28px;
}
.radios label p::after {
  border: 1px solid rgba(20, 30, 85, var(--theme-opacity-20, 0.2));
  border-radius: 50%;
  content: "";
  height: 10px;
  left: 9px;
  position: absolute;
  width: 10px;
}
.radios label input:checked + p {
  color: #141e55;
}

p.error {
  color: #971b2f;
  display: block;
  flex-basis: 100%;
  margin-top: 10px;
  position: relative;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 20px;
  line-height: 1.25rem;
}
p.error [data-error-code] {
  display: none;
}
p.error [data-force-initial-display] {
  display: block;
}

input {
  display: none;
}
input:checked + p::after {
  background-color: #141e55;
}`;

// src/components/jb-radio/jb-radio.ts
var JbRadio = class extends Initiable(FormElement(r2)) {
  _handleInputChange(e) {
    this.value = e.target.value;
  }
  render() {
    return ke`
      <div class="${Rt({ error: !!this.errorText || this.showError, disabled: !!this.disabled })}">
        ${this.label ? ke`<label class="main">${this.label}${this.requiredText ? "*" : ""}</label>` : D}
        <div class="radios">
          ${this.options.map(
      (option) => ke`
              <label>
                <input
                  .value=${option.value}
                  type="radio"
                  name="${this.name}"
                  ?checked="${option.value === this.value}"
                  ?required=${!!this.requiredText}
                  ?disabled=${this.disabled}
                  @change=${this._handleInputChange}
                />
                <p>${option.label}</p>
              </label>
            `
    )}
        </div>
        ${this.showError ? ke`<p class="error">${this.requiredText}</p>` : D}
        ${this.errorText ? ke`<p class="error">${this.errorText}</p>` : D}
      </div>
    `;
  }
};
JbRadio.styles = r(jb_radio_default);
__decorateClass([
  n({ type: Array })
], JbRadio.prototype, "options", 2);
JbRadio = __decorateClass([
  customElement("jb-radio")
], JbRadio);

export {
  JbRadio
};
